<template>
    <el-form ref="form" class="bg-white rounded-lg p-5 shadow-lg" :model="form" :rules="rules">
        <h2 class="text-xl mb-5 font-bold">活動資訊</h2>

        <div class="grid grid-cols-2 gap-4">
            <div class="border border-gray-100 rounded-lg p-2">
                <el-upload
                    ref="upload"
                    :list-type="'text'"
                    :on-change="handleChange"
                    :auto-upload="false"
                    :file-list="fileList"
                    :limit="1"
                    drag
                    action=""
                    :on-exceed="imageOverLimit"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG">
                    <div class="flex items-center justify-center w-full min-h-[300px] border p-2 border-gray-100 rounded-lg">
                        <!-- 上傳後呈現圓形圖效果 -->
                        <div v-if="imagePreview !== null" class="justify-center flex items-center">
                            <img class="rounded-lg w-full" :src="imagePreview" />
                        </div>
                        <div v-else class="flex-1 text-gray-400">
                            <!-- <i class="fas fa-cloud-upload-alt text-2xl"></i> -->
                            <font-awesome-icon class="icon" :icon="['fas', 'cloud-upload-alt']" />
                            上傳活動圖
                        </div>
                    </div>
                </el-upload>
                <div class="mt-2">
                    <!-- <button class="border-red-500 text-red-500 border rounded-lg px-2 py-1 mr-2">上傳圖片</button> -->
                    <button class="border-black border rounded-lg px-2 py-1" @click.prevent="removeImage">刪除圖片</button>
                </div>
            </div>
            <el-form-item>
                <div class="flex items-center">
                    <label>活動獎勵</label>
                    <button
                        v-permission="['update']"
                        class="border-orange-600 text-orange-600 border w-6 h-6 rounded-full ml-2"
                        @click.prevent="addRewardColumn(form.rewards.length)">
                        <div class="mt-[-10px]">
                            <font-awesome-icon :icon="['fas', 'plus']" />
                        </div>
                    </button>
                </div>
                <div v-for="(reward, index) in form.rewards" :key="index" class="mt-4 border rounded-lg p-2 py-5 relative">
                    <div v-if="index !== 0" class="absolute -top-5 z-50 right-0">
                        <span
                            v-permission="['update']"
                            class="cursor-pointer rounded-full w-6 h-6 border border-orange-500 bg-white p-1 text-sm mr-2"
                            @click="deleteReward(index)">
                            <i class="icofont-ui-delete text-orange-600 text-xl"></i>
                        </span>
                    </div>
                    <div class="flex">
                        <el-form-item class="w-full" :prop="`rewards[${index}].type`">
                            <el-select v-model="form.rewards[index].type" placeholder="請選擇" class="w-full">
                                <el-option v-for="(item, key) in { voucher: '快閃折抵金' }" :key="key" :label="item" :value="key"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="form.rewards[index].type === 'voucher'" class="w-full" :prop="`rewards[${index}].amount`">
                            <el-input v-model="form.rewards[index].amount" type="number" class="w-full" placeholder="贈送額度"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="index === 0" class="bg-red-600 w-[200px] mt-5 bg-opacity-30 text-red-600 p-1 px-2 text-center rounded-lg">
                        同步使用期限
                        <el-switch
                            v-model="form.isSameExpiredAt"
                            class="ml-2"
                            active-color="#FF5733"
                            inactive-color="#e5e5e5"
                            :active-value="true"
                            :inactive-value="false">
                        </el-switch>
                    </div>
                    <el-form-item class="mt-5" :prop="`rewards[${index}].expired_at`">
                        <el-date-picker v-model="form.rewards[index].expired_at" align="left" type="date" placeholder="獎勵使用期限（到期後消失）">
                        </el-date-picker>
                    </el-form-item>
                </div>
            </el-form-item>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <el-form-item class="w-full" prop="code">
                <label>
                    活動 ID (限 10 位英數混合)
                    <el-input v-model="form.code" class="w-full" :minlength="10" :maxlength="10"></el-input>
                </label>
            </el-form-item>
            <el-form-item class="w-full" prop="status">
                <label>
                    啟用狀態
                    <el-select v-model="form.status" class="w-full" placeholder="請選擇">
                        <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </label>
            </el-form-item>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <el-form-item class="w-full" prop="name">
                <label>
                    活動名稱
                    <el-input v-model="form.name" type="textarea" rows="7" class="w-full" :maxlength="250"></el-input>
                </label>
                <div class="text-right">
                    <span :class="form.name.length > maxLengthLimit ? 'text-orange-600' : ''">{{ form.name.length }}/{{ maxLengthLimit }}</span>
                </div>
            </el-form-item>
            <el-form-item class="w-full" prop="description">
                <label>
                    活動辦法
                    <el-input v-model="form.details.description" type="textarea" rows="7" class="w-full" :maxlength="250"></el-input>
                </label>
                <div class="text-right">
                    <span :class="form.details.description.length > systemMaxLengthLimit ? 'text-orange-600' : ''"
                        >{{ form.details.description.length }}/{{ systemMaxLengthLimit }}</span
                    >
                </div>
            </el-form-item>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <div>
                <el-form-item class="w-full" prop="isActiveDateBetween">
                    <label>使用期間</label>
                    <el-select v-model="form.isActiveDateBetween" class="w-full" placeholder="請選擇" @change="onIsActiveDateBetween">
                        <el-option v-for="item in activeDateBetweenOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="form.isActiveDateBetween" class="w-full" prop="campaignLife">
                    <label>
                        期間範圍
                        <el-date-picker
                            v-model="form.campaignLife"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="起始日期"
                            end-placeholder="結束日期">
                        </el-date-picker>
                    </label>
                </el-form-item>
            </div>
            <el-form-item class="w-full" prop="note">
                <label>
                    注意事項
                    <el-input v-model="form.note" type="textarea" rows="7" class="w-full" :maxlength="250"></el-input>
                </label>
                <div class="text-right">
                    <span :class="form.note.length > systemMaxLengthLimit ? 'text-orange-600' : ''"
                        >{{ form.note.length }}/{{ systemMaxLengthLimit }}</span
                    >
                </div>
            </el-form-item>
        </div>
        <div class="flex justify-center mt-3 border-gray-100 pt-2">
            <button class="black-btn-outline md:text-sm text-xs h-[40px] w-[150px] mr-5" @click.prevent="$router.push({ name: 'campaigns-list' })">
                返回列表
            </button>
            <button class="orange-btn-800 md:text-sm text-xs h-[40px] w-[150px]" :disabled="disabled" @click.prevent="onSubmit('form')">
                {{ isUpdate ? "編輯" : "新增" }}活動
            </button>
        </div>
    </el-form>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            form: {
                name: "",
                details: {
                    description: "",
                },
                note: "",
                campaignLife: [],
                rewards: [{ type: "voucher", amount: null, expired_at: null }],
                status: 0,
            },
            rules: {
                code: [
                    {
                        required: true,
                        message: "活動 ID 為必填",
                        trigger: "blur",
                    },
                    {
                        min: 10,
                        message: "活動 ID 最少為10位數",
                        trigger: "blur",
                    },
                    {
                        max: 10,
                        message: "活動 ID 最多為10位數",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "活動名稱為必填",
                        trigger: "blur",
                    },
                    {
                        max: 250,
                        message: "活動名稱最多 250 字",
                        trigger: "blur",
                    },
                ],
                isActiveDateBetween: [
                    {
                        required: true,
                        message: "使用期間為必填",
                        trigger: ["blur", "change"],
                    },
                ],
                description: [
                    {
                        max: process.env.VUE_APP_INPUT_MAX_LIMIT,
                        message: `活動細節最多 ${process.env.VUE_APP_INPUT_MAX_LIMIT} 字`,
                        trigger: "blur",
                    },
                ],
                note: [
                    {
                        max: process.env.VUE_APP_INPUT_MAX_LIMIT,
                        message: `注意事項最多 ${process.env.VUE_APP_INPUT_MAX_LIMIT} 字`,
                        trigger: "blur",
                    },
                ],
            },
            // 選取到的圖片檔
            photoFile: null,
            // 顯示預覽圖
            imagePreview: null,
            // 檔案列表
            fileList: [],
            // 選擇啟用狀態
            statusOptions: [
                { label: "啟用", value: 0 },
                { label: "關閉", value: -1 },
            ],
            // 選擇使用期間
            activeDateBetweenOptions: [
                { label: "不限活動期間", value: false },
                { label: "限定活動期間", value: true },
            ],
            // 字數限制
            maxLengthLimit: 250,
            // 字數限制3000
            systemMaxLengthLimit: process.env.VUE_APP_INPUT_MAX_LIMIT,
            disabled: false,
            isUpdate: false,
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 刪除圖片
         */
        removeImage() {
            this.fileList = [];
            this.photoFile = null;
            this.imagePreview = null;
        },
        //圖片變更
        handleChange(file) {
            if (file.size > 5 * 1024 * 1024) {
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: "圖片尺寸過大",
                });
                return;
            }
            // 檔案列表
            this.fileList = [];
            // 設定圖片為上傳檔案
            this.photoFile = file.raw;
            console.log(this.photoFile);
            // 新增一個讀取上傳檔案方法
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(this.photoFile);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                this.imagePreview = reader.result;
            };
        },
        //上傳圖片超過限制數量
        imageOverLimit() {
            this.$message({
                type: "error",
                message: "超過上傳圖片上限",
            });
        },
        /**
         * 判斷是否選擇 活動期間
         * @param { type Boolean(布林) } val 是否選擇活動期間
         */
        onIsActiveDateBetween(val) {
            // 判斷是否為啟用活動時間限制 (需要增加 表單驗證的規則)
            if (val) {
                // 新增表單規則 驗證是否有選擇使用期間
                console.log("work");
                this.rules["campaignLife"] = [
                    {
                        required: true,
                        message: "期間範圍為必填",
                        trigger: "blur",
                    },
                ];
            } else {
                // 刪除表單規則 驗證是否有選擇使用期間
                this.$delete(this.rules, "campaignLife");
            }
        },
        /**
         * 新增活動獎勵
         * @param { type Number(數字) } index 新增索引
         */
        addRewardColumn(index) {
            this.$set(this.form["rewards"], index, {
                type: "voucher",
                amount: null,
                expired_at: this.form.isSameExpiredAt ? this.form["rewards"][0].expired_at : null,
            });
            this.setRules(index);
        },
        /**
         * 設定表單驗證格式 用來增減 活動獎勵驗證規則
         * @param { type String(字串) } index 索引位置
         * @param { type Boolean(布林) } isAdd 判斷是否為新增
         */
        setRules(index, isAdd = true) {
            const keys = ["type", "amount", "expired_at"];
            const rule = [{ required: true, trigger: "blur" }];
            keys.forEach((key) => {
                switch (key) {
                    case "type":
                        rule[0]["message"] = "折抵金類別為必填";
                        break;
                    case "amount":
                        rule[0]["message"] = "折抵金額度為必填";
                        break;
                    case "expired_at":
                        rule[0]["message"] = "折抵金期限為必填";
                        break;
                }
                // 判斷是否為新增 活動獎勵
                if (isAdd) {
                    // 新增表單規則
                    this.rules[`rewards[${index}].${key}`] = rule;
                    // this.$set(this.rules, `rewards[${index}].${key}`, rule);
                } else {
                    // 刪除表單規則
                    this.$delete(this.rules, `rewards[${index}].${key}`);
                }
            });
        },
        /**
         * 刪除活動獎勵
         * @param { type Number(數字) } index 刪除索引
         */
        async deleteReward(index) {
            try {
                // await this.$confirm(`刪除此"${text}"按鈕?`, "提示", {
                //     confirmButtonText: "确定",
                //     cancelButtonText: "取消",
                //     type: "warning"
                // });
                this.form["rewards"].splice(index, 1);
                this.setRules(index, false);
            } catch (err) {
                console.log(err);
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                // 判斷是否有選擇 活動區間 開始日語結束日時間
                if (!this.$isEmpty(this.form.campaignLife)) {
                    // 活動開始日期
                    this.form.started_at = this.$moment(this.form.campaignLife[0]).format("YYYY-MM-DD");
                    // 活動結束日期
                    this.form.ended_at = this.$moment(this.form.campaignLife[1]).format("YYYY-MM-DD");
                }

                // 轉換 活動使用期限 日期格式
                this.form.rewards = this.form.rewards.map((item) => {
                    item["expired_at"] = this.$moment(item.expired_at).format("YYYY-MM-DD");
                    return item;
                });
                // 將物件資料轉換成 form 表單格式 (multiple form data)
                const result = await this.setFormData(this.form);
                // 判斷是否為更新
                if (this.isUpdate) {
                    this.update(result);
                } else {
                    this.create(result);
                }
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        /**
         * 設定表單資料
         * @param { type Object(物件) } form
         */
        setFormData(form) {
            return new Promise((resolve) => {
                const result = new FormData();
                Object.keys(form).forEach((objKey) => {
                    // 判斷非 details 或 rewards key 時才執行
                    if (objKey !== "details" || objKey !== "rewards") {
                        result.append(objKey, form[objKey]);
                    }
                    // 判斷 key 是 details 時 因為多一層物件要多跑一層回圈處理
                    if (objKey === "details") {
                        if (!this.$isEmpty(form.details)) {
                            Object.keys(form.details).forEach((detailsKey) => {
                                result.append(`details[${detailsKey}]`, form.details[detailsKey]);
                            });
                        }
                    }
                    // 判斷 key 是 rewards(活動獎勵) 時 因為是陣列 且陣列中又是物件 要多跑兩層回圈處理
                    if (objKey === "rewards") {
                        if (!this.$isEmpty(form.rewards)) {
                            form.rewards.forEach((item, index) => {
                                Object.keys(item).forEach((rewardsKey) => {
                                    result.append(`rewards[${index}][${rewardsKey}]`, item[rewardsKey]);
                                });
                            });
                        }
                    }
                });
                // 判斷是否有選擇圖檔
                if (!this.$isEmpty(this.photoFile)) {
                    result.append("image", this.photoFile);
                }
                // 判斷是否有預覽圖 沒有預覽圖 代表刪除活動圖片 或 為上傳圖片
                if (this.$isEmpty(this.imagePreview)) {
                    // 取消活動圖片時送空字串
                    result.append("image", "");
                }
                resolve(result);
            });
        },
        /**
         * 新增活動
         * @param { type Multiple Form Data(表單發送格式) } formData
         */
        async create(formData) {
            try {
                await this.$api.CampaignCreateApi(formData);
                this.$message({
                    type: "success",
                    message: "新增成功",
                });
                this.$router.push({ name: "campaigns-list" });
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        /**
         * 更新活動
         * @param { type Multiple Form Data(表單發送格式) } formData
         */
        async update(formData) {
            try {
                // 判斷是否為不啟用 限制活動時間
                if (!this.form.isActiveDateBetween) {
                    formData.append("started_at", "");
                    formData.append("ended_at", "");
                }
                await this.$api.CampaignUpdateApi(this.$route.params.campaignID, formData);
                this.$message({
                    type: "success",
                    message: "編輯成功",
                });
                this.getData(this.$route.params.campaignID);
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        /**
         * 取得活動資料
         * @param { type String(字串) } id 活動 id
         */
        async getData(id) {
            try {
                const { data } = await this.$api.GetCampaignDataApi(id);
                this.$set(this.form, "name", data.name);
                this.$set(this.form, "id", data.id);
                this.$set(this.form, "code", data.code);
                this.$set(this.form, "status", data.status);
                this.$set(this.form, "note", data.details.note ?? "");
                // 判斷是否有活動詳情
                if (!this.$isEmpty(data.details)) {
                    this.$set(this.form["details"], "description", typeof data.details.description !== "string" ? "" : data.details.description);
                    // 判斷是否有活動圖片
                    if (data.details.image !== undefined) {
                        this.imagePreview = data.details.image.url;
                    }
                }
                // 判斷有活動開始時間跟活動結束時間時才執行
                if (!this.$isEmpty(data.started_at) && !this.$isEmpty(data.ended_at)) {
                    this.$set(this.form, "isActiveDateBetween", true);
                    this.$set(this.form["campaignLife"], 0, this.$moment(data.started_at).format("YYYY-MM-DD"));
                    this.$set(this.form["campaignLife"], 1, this.$moment(data.ended_at).format("YYYY-MM-DD"));
                } else {
                    this.$set(this.form, "isActiveDateBetween", false);
                }
                // 判斷是否有活動獎勵資料
                if (!this.$isEmpty(data.rewards)) {
                    data.rewards.forEach((reward, index) => {
                        this.$set(this.form["rewards"], index, reward);
                        this.setRules(index);
                    });
                }
            } catch (err) {
                console.log(err);
                this.errorCallback({ err });
            }
        },
    },
    created() {
        this.setRules(0);
        // 判斷為更新活動頁面時才觸發
        if (this.$route.name === "campaign-update") {
            this.isUpdate = true;
            this.getData(this.$route.params.campaignID);
        }
    },
};
</script>

<style lang="scss" scoped>
label {
    @apply text-base text-gray-400;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    @apply w-full;
}
::v-deep .el-upload-dragger {
    @apply flex items-center justify-center border-none;
}
::v-deep .el-upload-list {
    @apply sr-only;
}
::v-deep .el-upload-dragger {
    @apply h-auto w-full overflow-visible;
}
::v-deep .el-upload {
    @apply w-full;
}
</style>
